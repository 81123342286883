import React from "react"
import withLayout from "../../layout"
import {FormattedMessage, injectIntl} from "react-intl"
import SEO from "../../components/SEO"
import HeroCaseStudy from "../../components/HeroCaseStudy"
import DropBg from "../../components/UI/DropBg"
import DropGradient from "../../images/case_studies/epictv/drop_gradient.svg"
import {Fade} from "react-reveal"
import SectionTitle from "../../components/CaseStudy/SectionTitle"
import {Heading, Paragraph} from "../../components/UI/Typography"
import FullWidthBgImage from "../../images/case_studies/epictv/full_width_image.png"
import BannerBgImage from "../../images/case_studies/epictv/banner.png"
import Drop from "../../components/UI/Drop"
import Image from "../../components/UI/Image"
import Results from "../../components/CaseStudy/Results"
import LottieController from "../../components/UI/LottieController"
import SimpleStats from "../../components/UI/SimpleStats"
import {useInView} from "react-intersection-observer"
import ResultsIcon1 from "../../components/UI/Lottie/Euro.json"
import ResultsIcon2 from "../../components/UI/Lottie/Grafico 1.json"
import ResultsIcon3 from "../../components/UI/Lottie/Carrello.json"
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory"
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage"

const EpicTvCaseStudy = ({intl}) => {
    const caseStudyColor = "#FF3060"
    const [resultsIcon1Ref, resultsIcon1View] = useInView()
    const [resultsIcon2Ref, resultsIcon2View] = useInView()
    const [resultsIcon3Ref, resultsIcon3View] = useInView()

    return (
        <>
            <SEO
                title={intl.formatMessage({id: 'epic_tv.meta_title'})}
                description={intl.formatMessage({id: 'epic_tv.meta_description'})}
                keywords={intl.formatMessage({id: 'epic_tv.meta_keywords'}).split(',') || []}
                lang={intl.locale}
            />

            <HeroCaseStudy
                heroImage="case_studies/epictv/hero.png"
                title="epic_tv.title"
                subtitle1="epic_tv.subtitle"
                color="linear-gradient(90.03deg, #FF3060 17%, #C71C44 105.99%)"
                categories={["portfolio.filters.marketing_strategy", "portfolio.filters.data_analysis_consulting"]}
                intl={intl}
            />
            {/*Overview*/}
            <div className="section-padding">
                <Drop
                    fill="#F4F5F8"
                    width={500}
                    style={{
                        position: "absolute",
                        bottom: -300,
                        left: -90,
                        zIndex: -1,
                        transform: "rotate(180deg)"
                    }}
                    className="d-none d-lg-block"
                />
                <DropBg
                    src={DropGradient}
                    bottom="5%"
                    left="25%"
                    className="d-none d-lg-block"
                />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Fade bottom delay={200}>
                                <SectionTitle
                                    number="01"
                                    text="epic_tv.overview"
                                    color={caseStudyColor}
                                />
                            </Fade>
                        </div>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-12 col-md-5">
                                <Heading level="3">
                                    <FormattedMessage id="epic_tv.client_title"/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id="epic_tv.client_paragraph"/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-12 col-md-6 offset-md-1">
                                <Heading level="4" lineHeight="2.3">
                                    <FormattedMessage id="epic_tv.client_needs_title"/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id="epic_tv.client_needs_paragraph"/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={FullWidthBgImage} imageWidth={1440} imageHeight={700}/>
            {/*Brief*/}
            <div className="section-padding">
                <Drop
                    width={360}
                    fill="#F4F5F8"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: -100
                    }}
                />
                <DropBg
                    src={DropGradient}
                    bottom="320px"
                    right="120px"
                    rotation={180}
                    className="d-none d-lg-block"
                />
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-2">
                                <Fade bottom delay={200}>
                                    <SectionTitle
                                        number="02"
                                        text="epic_tv.brief"
                                        color={caseStudyColor}
                                    />
                                </Fade>
                            </div>
                            <div className="col-12 col-md-8 offset-md-1">
                                <Fade bottom delay={400}>
                                    <Heading level="2" marginTop="0">
                                        <FormattedMessage id="epic_tv.brief_title"/>
                                    </Heading>
                                </Fade>
                                <Fade bottom delay={600}>
                                    <Paragraph>
                                        <FormattedMessage id="epic_tv.brief_paragraph"/>
                                    </Paragraph>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            {/*Workflow*/}
            <div className="container section-padding-bottom">
                <div className="row">
                    <div className="col-12">
                        <Fade bottom delay={200}>
                            <SectionTitle
                                number="03"
                                text="epic_tv.workflow"
                                color={caseStudyColor}
                            />
                        </Fade>
                    </div>
                </div>
                <div className="row">
                    <Fade bottom delay={400}>
                        <div className="col-12 col-md-7">
                            <Heading level="3">
                                <FormattedMessage id="epic_tv.solutions"/>
                            </Heading>
                            <Paragraph marginBottom="48px">
                                <FormattedMessage id="epic_tv.solutions_paragraph_1"/>
                            </Paragraph>
                            <Paragraph>
                                <FormattedMessage id="epic_tv.solutions_paragraph_2"/>
                            </Paragraph>
                            <Heading level="4" margin="40px 0 16px">
                                <FormattedMessage id="epic_tv.insights"/>
                            </Heading>
                            <Paragraph>
                                <FormattedMessage id="epic_tv.insights_paragraph_1"/>
                            </Paragraph>
                        </div>
                    </Fade>
                    <Fade bottom delay={600}>
                        <div className="col-12 offset-0 col-md-4 offset-md-1 mt-5 mt-md-0 position-relative">
                            <Image
                                filename="case_studies/epictv/solutions.png"
                                alt="Solutions"
                                className="case-study-right-pic"
                            />
                        </div>
                    </Fade>
                    <div className="col-12">
                        <Fade bottom delay={800}>
                            <Paragraph marginTop="48px">
                                <FormattedMessage id="epic_tv.insights_paragraph_2"/>
                            </Paragraph>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BannerBgImage} imageWidth={1568} imageHeight={589}/>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-12 col-md-7">
                                <Heading level="3">
                                    <FormattedMessage id="epic_tv.performance_adv_title"/>
                                </Heading>
                                <Paragraph marginBottom="48px">
                                    <FormattedMessage id="epic_tv.performance_adv_paragraph_1"/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id="epic_tv.performance_adv_paragraph_2"/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id="epic_tv.performance_adv_paragraph_3"/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={600}>
                            <div className="col-12 offset-0 col-md-4  offset-md-1 position-relative">
                                <Image
                                    filename="case_studies/epictv/performance_adv.png"
                                    alt="Performance Advertising"
                                    className="case-study-right-pic"
                                />
                            </div>
                        </Fade>
                        <div className="col-12">
                            <Fade bottom delay={800}>
                                <Paragraph marginTop="48px">
                                    <FormattedMessage id="epic_tv.performance_adv_paragraph_4"/>
                                </Paragraph>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <Results
                subtitle="epic_tv.results_paragraph"
                testimonialImg="marzia_cappetta.jpg"
                testimonialName="epic_tv.results_testimonial_name"
                testimonialQuote="epic_tv.results_testimonial_quote"
                testimonialRole="epic_tv.results_testimonial_role"
            >
                <div className="col-12 col-lg-3">
                    <div className="animation-wrapper mt-0" ref={resultsIcon1Ref}>
                        <LottieController
                            lottieJSON={ResultsIcon1}
                            inView={resultsIcon1View}
                            width="150px"
                            height="150px"
                        />
                        <SimpleStats
                            inView={resultsIcon1View}
                            valueFrom={0}
                            valueTo={20}
                            prefix="-"
                            headingStyle={{fontWeight: 500}}
                            labelWidth="250px"
                            measureUnit="%"
                            label="epic_tv.results_icon_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="animation-wrapper" ref={resultsIcon2Ref}>
                        <LottieController
                            lottieJSON={ResultsIcon2}
                            inView={resultsIcon1View}
                            width="255px"
                            height="150px"
                        />
                        <SimpleStats
                            inView={resultsIcon2View}
                            valueFrom={0}
                            valueTo={50}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            labelWidth="250px"
                            measureUnit="%"
                            label="epic_tv.results_icon_2"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="animation-wrapper" ref={resultsIcon3Ref}>
                        <LottieController
                            lottieJSON={ResultsIcon3}
                            inView={resultsIcon3View}
                            width="120px"
                            height="120px"
                        />
                        <SimpleStats
                            inView={resultsIcon3View}
                            valueFrom={0}
                            valueTo={36}
                            prefix="+"
                            headingStyle={{fontWeight: 500, marginTop: 60}}
                            labelWidth="250px"
                            measureUnit="%"
                            label="epic_tv.results_icon_3"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="portfolio.acea.title"
                desc="portfolio.acea.description"
                link="/case-study/acea/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "epic_tv", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(EpicTvCaseStudy));
